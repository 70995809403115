.brands .previews img{
    background-color: rgba(0, 0, 0, 0.09);
    max-width: 100%;
    margin: 0.5rem;
    max-height: 10rem;
    width: 100%;
}

.brands .previews button{
    display: none;
}