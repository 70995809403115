.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mr-4-rem {
    margin-right: 4.5rem !important;
}

.actionButtons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.flexContainer {
    display: flex;
    flex-direction: row;
    padding: 0;
}

.smallForm {
    max-width: 800px !important;
}

.listTable .MuiTableCell-sizeSmall:last-child {
    padding: 0px !important;
    margin: 0px !important;
}

.listTable > tbody > tr > td:last-child > [aria-label~=edit] {
    display: none !important;
}

.listTable > tbody > tr > td:last-child > [aria-label~=削除] {
    display: none !important;
}

.editableListTable {
    white-space: nowrap;
}

.editableListTable .column-undefined > button {
    visibility: visible !important;
}

.editableListTable > tbody > tr > td:last-child > [aria-label~=削除] {
    margin-left: 60px !important;
}

.w-10 {
    width: 10em !important;
}

.productToolBar > div .MuiButton-label {
    width: 140px !important;
    display: inline-flex !important;
    align-items: inherit !important;
    justify-content: inherit !important;
}

.productToolBar > div {
    display: inline-flex !important;
    padding-top: 1px !important;
    min-width: 80px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.ml-30 {
    margin-left: 30px !important;
}

.ml-42 {
    margin-left: 42px !important;
}

.btnPullRight {
    float: right;
}

.ml-80 {
    margin-left: 80px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-80 {
    margin-top: 80px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.breakLine {
    white-space: pre-line !important;
}

.mt-05 {
    margin-top: 5px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.ml-10 {
    margin-left: 10px !important;
}

.mr-10 {
    margin-right: 1em !important;
}

.ml-10-percent {
    margin-left: -10% !important;
}

.mt-225 {
    margin-top: 225px !important;
    margin-right: 10px !important;
    min-height: 1px !important;
    padding-bottom: 1px !important;
}

.mt-100 {
    margin-top: 100px !important;
}


.p-1 {
    padding: 1em;
}

.mr-50 {
    margin-right: 50px !important;
}

.mt-195-toolbar {
    margin-top: 195px !important;
    margin-right: 10px !important;
    min-height: 1px !important;
    padding-bottom: 1px !important;
}

.mt-350 {
    margin-top: 350px !important;
}

.text-center {
    text-align: center !important;
}

.border {
    border: 1px solid black !important;
}

.lh-30 {
    line-height: 30px;
}

pre {
    white-space: break-spaces !important;
    margin: 0em 0px !important;
    display: flex !important;
}

.ml-20 {
    margin-left: 20px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.numberRight {
    display: flex !important;
    justify-content: flex-end !important;
}

.w-100 {
    width: 100% !important;
}

.w-70 {
    width: 70% !important;
}

.w-50 {
    width: 50% !important;
}

.w-33 {
    width: 33% !important;
}

.w-30 {
    width: 30% !important;
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

.justify-center {
    justify-content: center !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.font-18 {
    font-size: 18px !important;
}

.font-bold {
    font-weight: bold !important;
}

.display-none {
    display: none !important;
}

.flex-row {
    display: flex !important;
    flex-direction: row !important;
}

.w-100 {
    width: 100% !important;
}

.w-60 {
    width: 60% !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.ml-150 {
    margin-left: 150px !important;
}

.ml-250 {
    margin-left: 250px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.ml-70 {
    margin-left: 70px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.m-10 {
    margin: 10px;
}

.productsList > thead > tr > th {
    font-size: 0.865rem;
    font-weight: bold;
    padding: 16px 0px 16px 16px !important;
    min-width: 100px;
}

.productsList {
    white-space: nowrap;
}

.productModal > p {
    font-family: sans-serif;
    color: red;
    margin: 0;
    white-space: pre-wrap;
}

/*for all table head to have a min-width*/
th {
    font-size: 0.765rem;
    padding: 16px 0px 16px 16px !important;
    min-width: 100px !important;
}

/*------------------------------*/

/*for all edit and delete*/
table td:last-child > [aria-label~=edit] {
    display: inline-grid !important;
    margin-right: 34px;
    margin-left: -10px;
}

table > td:last-child > [aria-label~=削除] {
    display: inline-grid !important;
}

/*------------------------------*/

.productForm .car-model-container {
    display: flex;
    grid-template-columns: 0fr 0fr 0fr;
    grid-gap: 20px;
    width: 100% !important;
    align-items: flex-start;
    justify-content: start;
}

.productForm .car-model-child {
    display: inline-block !important;
    min-width: 150px !important;
}

.productForm .car-model-child-button {
    display: inline-block !important;
    min-width: 150px !important;
    margin-left: auto !important;
}

.productForm .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    width: auto !important;
}

.productForm .grid-child {
    display: inline-block !important;
    min-width: 225px !important;
}

.productForm .MuiFilledInput-input {
    min-width: 225px !important;
}

.productForm .MuiFormControl-root {
    display: flex !important;
}

.productForm .RaSelectInput-input-62 {
    min-width: 225px !important;
}

.productIdsInput .MuiCardContent-root {
    padding: 0 !important;
}

.productIdsInput .bg-none {
    padding: 0 !important;
}

.w-125 {
    width: 100% !important;
}

.w-125 .MuiCard-root {
    overflow: visible !important;
}

@media screen and (max-width: 1600px) {

    .w-125 {
        width: 125% !important;
    }

}

.img-small-size {
    display: block;
    max-width: 250px;
    max-height: 150px;
    width: auto;
    height: auto;
}

.sm-input {
    width: 20em;
}

.w-30 {
    width: 30% !important;
}

.w-70 {
    width: 70% !important;
}

.w-100 {
    width: 100% !important;
}

.mt-19-px {
    margin-top: 19px !important;
}

.w-95 {
    width: 100% !important;
    margin: auto !important;
}

.h-100 {
    height: 100% !important;
}

.h-28 {
    height: 28em;
}

.d-flex {
    display: flex;
}

.mt-15 {
    margin-top: 15px;
}

.b-shadow {
    box-shadow: none !important;
}

.fw-bolder {
    font-weight: bolder;
}

.secondary {
    background: rgb(220, 0, 78) !important;
    color: white !important;
}

.f-20 {
    font-size: 18px !important;
}

.secondary:hover {
    box-shadow: -2px 3px 11px #8888a466;
    background: rgb(193 5 72) !important;
}

@media screen and (max-width: 714px) {

    .sm-input {
        width: 100%;
    }

}

.label-red {
    color: red;
}

.w-250 {
    min-width: 250px !important;
}

.w-150 {
    min-width: 150px !important;
}

.select-pr-150 .MuiSelect-filled.MuiSelect-filled {
    padding-right: 150px !important;
}

.w-225 {
    min-width: 190px !important;
}

.p-display-none > p {
    display: none;
}

@media screen and (max-width: 1300px) {

    .w-225 {
        width: 175px !important;
    }


}

.filterForBox > div {
    width: 100% !important;
    display: block !important;
}

.filterForBox form > div {
    width: 100% !important;
    display: block !important;
}

.filter-input {
    margin-right: 5px !important;
    display: none;
}

.filterBox {
    min-width: 1024px !important;
    margin-bottom: 10px !important;
}

.filterBox .MuiGrid-grid-lg-2 {
    flex-grow: 0;
    max-width: 16.666667%;
    flex-basis: 16.666667%;
}

.p-display-none .MuiFilledInput-adornedEnd {
    padding-right: 0px !important;
}

#login-form {
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    background: #f5f5f5;
    z-index: 9999;
    top: 0;
    border: 0;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 1300px) {
    #login-form > div > div {
        max-width: 45% !important;
        flex-basis: 100% !important;
    }
}

@media screen and (max-width: 1000px) {
    #login-form > div > div {
        width: 90% !important;
        max-width: 90% !important;
        flex-basis: 100% !important;
    }

}

.input-filled-basic > div > input {
    text-align: right;
}

.input-filled-basic-red-line > div > input {
    text-align: right;
    color: red;
}

.flex-row {
    display: flex !important;
    flex-direction: row !important;
}

.flex-row:nth-child(n) {
    overflow: hidden !important;
}

.temp-img-card {
    background: #aeaeae !important;
    width: 170px !important;
    height: 120px !important;
}


/*recommended for position input*/
.input-field {
    text-align: left !important;
    width: 100% !important;
    min-width: 180px !important;
}

/*--------------------------*/

/*recommended for long label*/
.small-width {
    min-width: 227px !important;
}

.big-width {
    min-width: 250px !important;
}

/*--------------------------*/

/*--------------------------*/

.displayNone {
    display: none !important;
}

.ml-5 {
    margin-left: 5px !important;
}

.ml-100 {
    margin-left: 100px !important;
}

.float-right {
    float: right !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.justify-center {
    justify-content: center !important;
}

.bg-none {
    background: none !important;
}

.f-16 {
    font-size: 16px !important;
}

.table-striped tr th {
    border: 1px solid black;
    background: #80808036;
    padding-left: 0 !important;
}

.table-striped tr th:nth-child(1) {
    background: gray;
    color: white;
    padding-left: 20px !important;
}

.table-striped tr td {
    border: 1px solid black;
}

.app-bar-title {
    flex: 1;
}

th.MuiTableCell-sizeSmall {
    padding-right: 22px !important;
}

.searchForm {
    max-width: 1200px;
}

.searchForm-sizeLarge {
    max-width: 2216px;
    width: 2216px;
}

.borderGrid {
    border-top: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
}

.borderGrid > div {
    border-bottom: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
}

.flex-start {
    justify-content: flex-start !important;
}

.nowrap {
    white-space: nowrap;
}

.app-bar-save-button {
    top: 57px;
    position: fixed;
    left: 0;
    margin-left: 14em;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.0s ease-out;
}

.app-bar-save-button > button {
    height: 32px !important;
    width: 87px !important;
}

.ml-9 {
    margin-left: 9.5em !important;
}

@media screen and (max-width: 599px) {
    .app-bar-save-button {
        margin-left: 1em;
    }
}

.fitting-table-cell {
    padding: 0 !important;
    max-width: 300px !important;
    min-width: 200px !important;
}

.fitting-table-cell-right-margin {
    padding-right: 30px !important;
    max-width: 60px !important;
    min-width: 75px !important;
}

.cropImgInput [data-testid="dropzone"] {
    width: 130px;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.ellipsis-600 {
    width: 600px; /* 省略せずに表示するサイズを指定 */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.my-pagination {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style-type: none;
    padding: 0 5rem;
}

.my-pagination > li > a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
}

.my-pagination > li.previous > a,
.my-pagination > li.next > a,
.my-pagination > li.break > a {
    border-color: transparent;
}

.my-pagination > li.selected {
    border-radius: 7px;
    padding: 0.1rem;
}

.my-pagination > li.selected > a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
}

.my-pagination > li.disabled > a {
    color: grey;
}

.my-pagination > li.disable,
.my-pagination > li.disabled > a {
    cursor: default;
}

.create {
    height: 2.3em;
    padding: 1em;
    width: 7em;
}

.create > span {
    justify-content: flex-end;
    background-image: url(createLogo.svg);
    background-repeat: no-repeat;
    filter: invert(100%);
    height: 18px;
    padding: 0px;
    width: 51px;
}

.create > span > span {
    filter: invert(100%) !important;
}

.button-link-gray {
    height: 48px !important;
    margin-top: 5px !important;
    color: black !important;
    background-color: lightgray !important;
}

.rm-padding-left {
    padding-left: 0 !important;
}

